<template>
  <div :class="$style.root">
    <VForm v-if="!isDetail && !isUser" :loading="loading" v-model="formValidity" ref="form">
      <SectionCard>
        <div :class="$style.header">
          <div :class="$style.title">{{ get(type, 'text') }} Аккаунта Организации #{{ this.id }}</div>
          <div :class="[$style.status, statusClass]">{{ get(currentStatus, 'text') }}</div>
        </div>
        <div v-if="!isEmpty(detail) && !loading">
          <template v-if="isDelete">
            <AccountApplicationField
              class="mt-5"
              v-model="payload['commentToDelete']"
              label="Комментарий"
              :can-edit="canEdit"
              :rules="(() => customValidationRules(get(rules, 'required'), 'commentToDelete'))()"
              :disabled="(() => disabledFields('commentToDelete'))()"
              rows="2"
              type="textarea"
            />
          </template>
          <template v-else>
            <VRow>
              <VCol cols="12" sm="6" md="4">
                <AccountApplicationField
                  label="Форма Организации"
                  v-model="payload['type']"
                  :items="organisationTypes"
                  :can-edit="canEdit"
                  :rules="(() => customValidationRules(get(rules, 'required'), 'type'))()"
                  :disabled="(() => disabledFields('type'))()"
                  item-text="text"
                  item-value="value"
                  type="select"
                />
              </VCol>
            </VRow>
            <VCheckbox v-if="isCreate" v-model="hasKSC" :disabled="!canEdit" outlined dense label="У меня есть код MDG (КСК)" class="d-inline-flex"/>
            <template v-if="hasKSC">
              <VRow>
                <VCol cols="12" sm="6" md="4">
                  <AccountApplicationField
                    v-model="payload['codeKsc']"
                    :label="kskField.label"
                    :can-edit="canEdit"
                    :rules="(() => customValidationRules(kskField.rules, kskField.key))()"
                    :readonly="notEditableFields.includes(kskField.key) && isEdit"
                    :disabled="(() => disabledFields(kskField.key))()"
                  />
                </VCol>
              </VRow>
            </template>
            <AccountApplicationAddress
              :can-edit="canEdit"
              :is-edit="isEdit"
              :organization-type="get(payload, 'type')"
              v-model="payload"
            />
            <VRow>
              <template v-for="{ label, key } in filesFields">
                <VCol
                  v-if="(canEdit || (!canEdit && !isEmpty(payload[key]))) && !(isManual && key === 'verificationLetter')"
                  cols="12" sm="6" md="4" lg="3"
                  class="d-flex flex-column"
                  :key="key"
                >
                  <div class="font-weight-bold mb-2">
                    {{ label }}
                    <VTooltip
                      v-if="key === 'verificationLetter'"
                      right
                      max-width="400"
                      location-strategy="connected"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <VIcon class="primary--text" v-bind="attrs" v-on="on">mdi-information-outline</VIcon>
                      </template>
                      Заверенное подписью директора и печатью организации письмо о полномочиях пользователя на подачу заявки на создания аккаунта организации
                    </VTooltip>
                  </div>
                  <AccountApplicationField
                    v-model="payload[key]"
                    :label="label"
                    required
                    :can-edit="canEdit"
                    :readonly="!canEdit"
                    :disabled="(() => disabledFields(key))()"
                    :entity="id"
                    section="account-application"
                    type="file"
                  />
                </VCol>
              </template>
            </VRow>
          </template>
        </div>
      </SectionCard>
      <SectionCard v-if="!isEmpty(detail) && !loading && !isDelete">
        <div :class="[$style.title, 'mb-5']">Руководитель организации</div>
        <VRow class="mb-7">
          <VCol cols="12" sm="6" md="4" lg="3" v-for="{ label, key, rules, mask } in leaderFields" :key="key">
            <AccountApplicationField
              v-model="payload[key]"
              :label="label"
              :can-edit="canEdit"
              :rules="(() => customValidationRules(rules, key))()"
              :disabled="(() => disabledFields(key))()"
              :mask="mask"
            />
          </VCol>
        </VRow>
        <div :class="[$style.title, 'mb-5']">Контактное лицо</div>
        <VRow>
          <VCol cols="12" sm="6" md="4" lg="3" v-for="{ label, key, rules, mask } in contactPersonFields" :key="key">
            <AccountApplicationField
              v-model="payload[key]"
              :label="label"
              :can-edit="canEdit"
              :rules="(() => customValidationRules(rules, key))()"
              :disabled="(() => disabledFields(key))()"
              :mask="mask"
            />
          </VCol>
        </VRow>
      </SectionCard>
    </VForm>
    <template>
      <VTabs
        v-if="isDetail || isUser"
        class="mb-4"
        v-model="tabData"
        @change="onTabChange"
      >
        <VTab key="account-application-basic" href="#account-application-basic">Основная информация</VTab>
        <VTab key="account-application-extra" href="#account-application-extra">Дополнительные данные</VTab>
        <VTab v-if="showAdminTab" key="account-application-settings" href="#account-application-settings">Настройки аккаунта</VTab>
      </VTabs>
      <VTabsItems v-model="tabData" class="mt-4">
        <VTabItem key="account-application-basic" value="account-application-basic">
          <template v-if="!(!isDetail && !isUser)">
            <div :class="[$style.detailHeader, 'mb-5']">
              <div :class="$style.title">Аккаунт Организации{{ isUser ? ` #${this.id}` : ''}}</div>
              <div :class="[$style.status, verifiedClass]">{{ isVerified ? "Подтверждён" : "Не подтверждён" }}</div>
            </div>
            <VForm v-if="!isEmpty(detail) && !loading">
              <VRow>
                <VCol cols="12" sm="6" md="4">
                  <AccountApplicationField
                    label="Форма Организации"
                    v-model="payload['type']"
                    :items="organisationTypes"
                    :can-edit="canEdit"
                    :rules="(() => customValidationRules(get(rules, 'required'), 'type'))()"
                    :disabled="(() => disabledFields('type'))()"
                    item-text="text"
                    item-value="value"
                    type="select"
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol cols="12" sm="6" md="4">
                  <AccountApplicationField
                    :value="payload['codeKsc']"
                    label="Код MDG (КСК)"
                    :can-edit="false"
                  />
                </VCol>
              </VRow>
              <AccountApplicationAddress
                :can-edit="false"
                :is-edit="isEdit"
                :organization-type="get(payload, 'type')"
                v-model="payload"
              />
              <VRow>
                <template v-for="{ label, key } in filesFields">
                  <VCol
                    v-if="!isEmpty(payload[key]) || isInitiator"
                    cols="12" sm="6" md="4" lg="3"
                    class="d-flex flex-column"
                    :key="key"
                  >
                    <div class="font-weight-bold mb-2">
                      {{ label }}
                      <VTooltip
                        v-if="key === 'verificationLetter'"
                        right
                        max-width="400"
                        location-strategy="connected"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <VIcon class="primary--text" v-bind="attrs" v-on="on">mdi-information-outline</VIcon>
                        </template>
                        Заверенное подписью директора и печатью организации письмо о полномочиях пользователя на подачу заявки на создания аккаунта организации
                      </VTooltip>
                    </div>
                    <AccountApplicationField
                      v-model="payload[key]"
                      :label="label"
                      :can-edit="isInitiator"
                      :readonly="!isInitiator"
                      :disabled="(() => disabledFields(key))()"
                      :entity="id"
                      type="file"
                    />
                  </VCol>
                </template>
              </VRow>
              <SectionCard>
                <div :class="[$style.title, 'mb-5']">Руководитель организации</div>
                <VRow class="mb-7">
                  <VCol cols="12" sm="6" md="4" lg="3" v-for="{ label, key } in leaderFields" :key="key">
                    <AccountApplicationField
                      v-model="payload[key]"
                      :label="label"
                      :can-edit="isInitiator"
                    />
                  </VCol>
                </VRow>
                <div :class="[$style.title, 'mb-5']">Контактное лицо</div>
                <VRow>
                  <VCol cols="12" sm="6" md="4" lg="3" v-for="{ label, key } in contactPersonFields" :key="key">
                    <AccountApplicationField
                      v-model="payload[key]"
                      :label="label"
                      :can-edit="isInitiator"
                    />
                  </VCol>
                </VRow>
              </SectionCard>
            </VForm>
          </template>
          <AccountApplicationTimeLine :id="id" :isDetail="isDetail || isUser" />
        </VTabItem>
        <VTabItem key="account-application-extra" value="account-application-extra">
          <AccountApplicationExtra :id="id" />
        </VTabItem>
        <VTabItem v-if="showAdminTab" key="account-application-settings" value="account-application-settings">
          <AccountApplicationSettings :id="id" />
        </VTabItem>
      </VTabsItems>
      <VFooter app v-if="!isDetail">
        <fieldset :class="[$style.fieldset, 'py-3 grow']" :disabled="loading">
          <VBtn class="mr-6" outlined depressed @click="goToBack">
            <VIcon>mdi-arrow-left</VIcon>
            Назад к списку
          </VBtn>
          <template v-if="this.isManual">
            <VBtn color="primary" class="mr-2" depressed :disabled="!diff" @click="onSave">Сохранить</VBtn>
            <VBtn color="success" class="d-inline-block float-right mr-2" depressed :disabled="!formValidity || !canSend || diff" @click="onCreateAccount">Создать</VBtn>
          </template>
          <template v-else>
            <template v-if="isInitiator && !isApproved">
              <VBtn color="primary" class="mr-2" depressed :disabled="isApproving || !diff" @click="onSave">Сохранить</VBtn>
              <VBtn color="primary" class="mr-2" depressed :disabled="isApproving || !diff" @click="onReset">Отменить</VBtn>
              <VBtn color="primary" class="mr-2" depressed :disabled="isApproving || isRework" @click="onDelete">Удалить</VBtn>
              <VBtn v-if="(isApproving || isRework) && canRevoke" color="primary" class="mr-2" depressed @click="onRevoke">Отозвать</VBtn>
              <div class="d-inline-block float-right">
                <AccountApplicationDialog @submit="submitToApprove" :required="false">
                  <template #message>
                    <p>Вы уверены, что хотите отправить аккаунт на подтверждение компанией ЕВРАЗ?</p>
                    <p>Подтверждение аккаунта нужно для расширения возможностей пользователя компании</p>
                    <p>Если у вас есть действующий или завершённый договор с компанией ЕВРАЗ, уточните код КСК у вашего куратора / менеджера</p>
                  </template>
                  <template #button="{ on, attrs }">
                    <VBtn v-bind="attrs" v-on="on" color="success" class="mr-2" depressed :disabled="!formValidity || !canSend || isApproving || diff">Отправить</VBtn>
                  </template>
                </AccountApplicationDialog>
              </div>
            </template>
            <template v-if="isAdministrator && !isApproved">
              <VBtn v-if="canReturnToApprove" color="error" class="mr-2" depressed @click="onReturnToApprove">Разблокировать</VBtn>
              <VBtn v-if="canReject" color="error" class="mr-2" depressed :disabled="!isApproving" @click="onReject">Отклонить</VBtn>
              <AccountApplicationDialog @submit="onRework" v-if="canRework">
                <template #button="{ on, attrs }">
                  <VBtn v-bind="attrs" v-on="on" color="primary" class="mr-2" depressed :disabled="!isApproving">На доработку</VBtn>
                </template>
              </AccountApplicationDialog>
              <AccountApplicationDialog @submit="submitOnApprove" v-if="canApprove">
                <template #button="{ on, attrs }">
                  <VBtn v-bind="attrs" v-on="on" color="success" class="mr-2 float-right" depressed :disabled="!isApproving">Согласовать</VBtn>
                </template>
              </AccountApplicationDialog>
            </template>
          </template>
        </fieldset>
      </VFooter>
      <VFooter app v-else-if="isInitiator && tabData === 'account-application-basic'">
        <fieldset :class="[$style.fieldset, 'py-3 grow']" :disabled="loading">
          <VBtn color="primary" class="mr-2" depressed :disabled="!diff" @click="onUpdate">Сохранить</VBtn>
          <VBtn color="primary" class="mr-2" depressed :disabled="!diff" @click="onReset">Отменить</VBtn>
        </fieldset>
      </VFooter>
    </template>
    <VDialog v-model="accountExistDialog" max-width="900" scrollable>
      <VCard tile>
        <VToolbar flat dark color="primary">
          <VToolbarTitle class="px-2">
            Аккаунт уже активен
          </VToolbarTitle>
          <VSpacer/>
          <VBtn icon dark @click="() => accountExistDialog = false">
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </VToolbar>
        <VCardText class="pt-5 text-center">
          <div class="text-h6 font-weight-regular">Аккаунт, который вы пытаетесь создать, уже активен.</div>
          <div class="text-h6 font-weight-regular">Вы можете отправить Администратору Аккаунта заявку на добавление в качестве пользователя.</div>
          <VBtn color="primary" class="mt-5" depressed @click="onInvite">Отправить заявку на добавление в аккаунт</VBtn>
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>
<script>
import SectionCard from '@/components/user/SectionCard/SectionCard.vue';
import {first, get, isArray, isEmpty, toNumber, toString, values} from 'lodash-es';
import {mapActions, mapGetters} from 'vuex';
import {
  ACCOUNT_APPLICATION_ACTIONS,
  ACCOUNT_APPLICATION_TYPES,
  ACCOUNT_APPLICATION_STATUSES,
  ACCOUNT_APPLICATION_CREATE_TYPES, ACCOUNT_APPLICATION_ORGANIZATION_TYPES,
} from '@/store/account-application/enums';
import AccountApplicationTimeLine from '@/components/account-application/components/AccountApplicationTimeline.vue';
import AccountApplicationDialog from '@/components/account-application/components/AccountApplicationDialog.vue';
import AccountApplicationExtra from '@/components/account-application/components/AccountApplicationExtra.vue';
import AccountApplicationField from '@/components/account-application/components/AccountApplicationField.vue';
import AccountApplicationAddress from '@/components/account-application/components/AccountApplicationAddress.vue';
import AccountApplicationSettings from "@/components/account-application/components/AccountApplicationSettings.vue";

export default {
  name: 'AccountApplication',
  components: {
    AccountApplicationSettings,
    AccountApplicationAddress,
    AccountApplicationField,
    AccountApplicationExtra,
    AccountApplicationDialog,
    AccountApplicationTimeLine,
    SectionCard,
  },
  props: {
    id: { type: String, required: true },
    isDetail: { type: Boolean }, // Компонент является детальной заявки
    isUser: { type: Boolean }, // Компонент является информацией об аккаунте
    isManualApplication: { type: Boolean }, // Компонент для ручного создания заявки
  },
  data() {
    const rules = {
      required: [(v) => !!v || 'Обязательное поле'],
      ksc: [
        (value) => /\d+$/.test(value) || 'Укажите корректный КСК',
      ],
      phone: [
        (value) => /[+7] \(\d{3}\) \d{3} \d{2} \d{2}/.test(value) || 'Укажите корректный телефон',
      ],
      email: [
        (value) => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(value) || 'Укажите корректный email',
      ]
    }

    return {
      hasKSC: false,
      formValidity: false,
      payload: this.getPayload(),
      rules,
      accountExistDialog: false,
      tabData: '',
      notEditableFields: ['inn', 'codeKsc', 'kpp'],
      organisationTypes: values(ACCOUNT_APPLICATION_ORGANIZATION_TYPES),
      kskField: {
        label: 'Код MDG (КСК)',
        key: 'codeKsc',
        rules: rules.required
      },
      filesFields: [
        {
          label: 'Письмо о полномочиях представителя компании',
          key: 'verificationLetter',
        },
        {
          label: 'Устав предприятия в полном объеме',
          key: 'companyCharter',
        },
        {
          label: 'Выписка из ЕГРЮЛ',
          key: 'companyEgrul',
        },
        {
          label: 'Протокол собрания о назначении руководителя предприятия',
          key: 'companyElectronProtocol',
        },
        {
          label: 'Свидетельство о постановке на учёт в налоговом органе',
          key: 'companyNalogCert',
        },
        {
          label: 'Свидетельство о регистрации (ОГРН)',
          key: 'companyOgrnCert',
        },
        {
          label: 'Приказ о назначении директора',
          key: 'companyDirectorOrder',
        },
      ],
      leaderFields: [
        {
          label: 'ФИО',
          key: 'leaderFio',
          rules: [...rules.required]
        },
        {
          label: 'Должность',
          key: 'leaderPosition',
          rules: [...rules.required]
        },
        {
          label: 'Телефон',
          key: 'leaderPhone',
          mask: '+7 (###) ### ## ##',
          rules: [...rules.required, ...rules.phone]
        },
        {
          label: 'Почта',
          key: 'leaderEmail',
          rules: [...rules.required, ...rules.email]
        },
      ],
      contactPersonFields: [
        {
          label: 'ФИО',
          key: 'contactPersonFio',
          rules: [...rules.required]
        },
        {
          label: 'Телефон',
          key: 'contactPersonPhone',
          mask: '+7 (###) ### ## ##',
          rules: [...rules.required, ...rules.phone]
        },
        {
          label: 'Почта',
          key: 'contactPersonEmail',
          rules: [...rules.required, ...rules.email]
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      pending: 'accountApplication/pending',
      accountApplicationDetail: 'accountApplication/detail',
      accountDetail: 'account/detail',
      accountPending: 'account/pending',
      hasStatus: 'accountApplication/hasStatus',
      hasTypeAccountApplication: 'accountApplication/hasType',
      hasTypeAccount: 'account/hasType',
      hasAction: 'accountApplication/hasAction',
      hasRole: 'user/hasRole',
    }),
    detail() {
      return this.isDetail || this.isUser ? this.accountDetail : this.accountApplicationDetail;
    },
    loading() {
      return this.pending || this.accountPending;
    },
    type() {
      return get(ACCOUNT_APPLICATION_CREATE_TYPES, [get(this.detail, 'type')])
    },
    isEdit() {
      return this.hasType(get(ACCOUNT_APPLICATION_CREATE_TYPES, 'EDIT.value'))
    },
    isVerified() {
      return get(this.detail, 'isVerified');
    },
    isCreate() {
      return this.hasType(get(ACCOUNT_APPLICATION_CREATE_TYPES, 'CREATE.value'))
    },
    isDelete() {
      return this.hasType(get(ACCOUNT_APPLICATION_CREATE_TYPES, 'DELETE.value'))
    },
    isManual() {
      return this.hasType(get(ACCOUNT_APPLICATION_CREATE_TYPES, 'MANUAL.value')) || this.isManualApplication;
    },
    currentStatus() {
      return get(ACCOUNT_APPLICATION_STATUSES, [get(this.detail, 'status')])
    },
    statusClass() {
      return {
        'grey lighten-4': this.isDraft,
        'orange lighten-4': this.isApproving,
        'error lighten-4': this.isRework,
        'green lighten-4': this.isApproved,
      };
    },
    verifiedClass() {
      return {
        'green lighten-4': this.isVerified,
        'error lighten-4': !this.isVerified,
      };
    },
    isInitiator() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'INITIATOR.value'), 'account');
    },
    isAccountAuthority() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'ACCOUNT_AUTHORITY.value'), 'account');
    },
    isAccountSlave() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'ACCOUNT_SLAVE.value'), 'account');
    },
    isAdministrator() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'ADMINISTRATOR.value'), 'account');
    },
    isAccountObserver() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'ACCOUNT_OBSERVER.value'), 'account');
    },
    canViewAccount() {
      return this.isAdministrator || this.isAccountObserver;
    },
    isDraft() {
      return this.hasStatus(get(ACCOUNT_APPLICATION_STATUSES, 'DRAFT.value'))
    },
    isRework() {
      return this.hasStatus(get(ACCOUNT_APPLICATION_STATUSES, 'ON_REWORK.value'))
    },
    isApproving() {
      return this.hasStatus(get(ACCOUNT_APPLICATION_STATUSES, 'ON_REVIEWER_APPROVING.value'))
    },
    isRejected() {
      return this.hasStatus(get(ACCOUNT_APPLICATION_STATUSES, 'REJECTED.value'))
    },
    isApproved() {
      return this.hasStatus(get(ACCOUNT_APPLICATION_STATUSES, 'APPROVED.value'))
    },
    isSaved() {
      return this.hasStatus(get(ACCOUNT_APPLICATION_STATUSES, 'SAVED.value'))
    },
    isActive(){
      return this.detail.isActive;
    },
    canReturnToApprove() {
      return this.hasAction([
        get(ACCOUNT_APPLICATION_ACTIONS, 'RETURN_TO_APPROVE_BY_REVIEWER.value'),
      ])
    },
    canApprove() {
      return this.hasAction([
        get(ACCOUNT_APPLICATION_ACTIONS, 'APPROVE_BY_REVIEWER.value'),
      ])
    },
    canReject() {
      return this.hasAction([
        get(ACCOUNT_APPLICATION_ACTIONS, 'REJECT_BY_REVIEWER.value'),
      ])
    },
    canSend() {
      return this.hasAction([
        get(ACCOUNT_APPLICATION_ACTIONS, 'SEND_TO_APPROVE_BY_INITIATOR.value'),
        get(ACCOUNT_APPLICATION_ACTIONS, 'SEND_FROM_REWORK_BY_INITIATOR.value'),
        get(ACCOUNT_APPLICATION_ACTIONS, 'SAVE.value'),
      ])
    },
    showAdminTab() {
      return this.isUser && (this.isAdministrator || this.isAccountObserver);
    },
    canRevoke(){
      return this.hasAction([get(ACCOUNT_APPLICATION_ACTIONS, 'REVOKE_BY_INITIATOR.value')])
    },
    canRework() {
      return this.hasAction([get(ACCOUNT_APPLICATION_ACTIONS, 'SEND_TO_REWORK_BY_REVIEWER.value')])
    },
    canEdit() {
      return (this.isManual && this.isDraft) || this.isInitiator && (this.isDraft || this.isRework);
    },
    diff() {
      return JSON.stringify(this.getPayload()) !== JSON.stringify(this.payload);
    },
  },
  methods: {
    isEmpty, get,
    ...mapActions({
      update: 'accountApplication/updateAccountApplication',
      updateAccount: 'account/updateAccount',
      getAccountDetail: 'account/getAccountDetail',
      getAccountApplicationDetail: 'accountApplication/getAccountApplicationDetail',
      deleteAccountApplication: 'accountApplication/deleteAccountApplication',
      applyActionAccountApplication: 'accountApplication/applyActionAccountApplication',
      fetchTimeline: 'accountApplication/fetchTimeline',
      sendInviteToAccount: 'accountApplication/sendInviteToAccount',
    }),
    hasType(types) {
      return this.isDetail || this.isUser ? this.hasTypeAccount(types) : this.hasTypeAccountApplication(types);
    },
    getPayload() {
      const path = this.isDetail || this.isUser ? '' : 'account.';
      return {
        codeKsc: get(this.detail, `${path}codeKsc`),
        inn: get(this.detail, `${path}inn`),
        kpp: get(this.detail, `${path}kpp`),
        companyNameFull: get(this.detail, `${path}companyNameFull`),
        companyNameShort: get(this.detail, `${path}companyNameShort`),
        country: get(this.detail, `${path}address.country`),
        countryIsoCode: get(this.detail, `${path}address.countryIsoCode`),
        regionIsoCode: get(this.detail, `${path}address.regionIsoCode`),
        region: get(this.detail, `${path}address.region`),
        zipCode: get(this.detail, `${path}address.zipCode`),
        city: get(this.detail, `${path}address.city`),
        street: get(this.detail, `${path}address.street`),
        building: get(this.detail, `${path}address.building`),
        house: get(this.detail, `${path}address.house`),
        flat: get(this.detail, `${path}address.flat`),
        verificationLetter: get(this.detail, `${path}verificationLetter`),
        type: get(this.detail, `${path}type`),
        taxId: get(this.detail, `${path}taxId`),

        companyCharter: get(this.detail, `${path}companyCharter`),
        companyEgrul: get(this.detail, `${path}companyEgrul`),
        companyElectronProtocol: get(this.detail, `${path}companyElectronProtocol`),
        companyNalogCert: get(this.detail, `${path}companyNalogCert`),
        companyOgrnCert: get(this.detail, `${path}companyOgrnCert`),
        companyDirectorOrder: get(this.detail, `${path}companyDirectorOrder`),
        commentToDelete: toString(get(this.detail, 'commentToDelete')),

        contactPersonEmail: toString(get(this.detail, `${path}contactPerson.email`)),
        contactPersonFio: toString(get(this.detail, `${path}contactPerson.fio`)),
        contactPersonPhone: toString(get(this.detail, `${path}contactPerson.phone`)),

        leaderEmail: toString(get(this.detail, `${path}organizationLeader.email`)),
        leaderFio: toString(get(this.detail, `${path}organizationLeader.fio`)),
        leaderPhone: toString(get(this.detail, `${path}organizationLeader.phone`)),
        leaderPosition: toString(get(this.detail, `${path}organizationLeader.position`)),
      };
    },
    goToBack() {
      if (this.isUser) {
        this.$router.push({ name: 'account/accountView', query: { tab: 'account-list' } })
      } else {
        this.$router.push({ name: 'account/accountView', query: { tab: 'account-applications' } })
      }
    },
    onTabChange(tab) {
      const { query } = this.$route;
      if (toString(get(query, 'tabAccountApplication', '')) !== tab) {
        this.$router.push({ query: { ...query, tabAccountApplication: tab, } });
      }
    },
    disabledFields(key) {
      if (key === 'type') {
        return false
      }
      return !get(this.payload, 'type');
    },
    customValidationRules(fieldRules, key) {
      if (this.disabledFields(key)) return [];
      return fieldRules;
    },
    // eslint-disable-next-line no-unused-vars
    isShow(key) {
      return true
    },
    onReset() {
      this.$refs.form?.resetValidation();
      this.payload = this.getPayload();
      this.hasKSC = !!get(this.payload, 'codeKsc');
    },
    async updateState() {
      if (this.isDetail || this.isUser) await this.getAccountDetail({ id: this.canViewAccount ? this.id : null })
      else await this.getAccountApplicationDetail({ id: this.id })

      this.payload = this.getPayload();
      this.hasKSC = !!get(this.payload, 'codeKsc');
      this.$refs.form?.resetValidation();
    },
    async onSave() {
      const result = await this.update({
        id: get(this.detail, 'id'),
        fields: {
          commentToDelete: toString(get(this.payload, 'commentToDelete')),
          account: {
            codeKsc: get(this.payload, 'codeKsc'),
            inn: get(this.payload, 'inn'),
            kpp: get(this.payload, 'kpp'),
            type: get(this.payload, 'type'),
            companyNameFull: get(this.payload, 'companyNameFull'),
            companyNameShort: get(this.payload, 'companyNameShort'),
            taxId: get(this.payload, 'taxId'),
            address: {
              country: get(this.payload, 'country'),
              countryIsoCode: get(this.payload, 'countryIsoCode'),
              regionIsoCode: get(this.payload, 'regionIsoCode'),
              region: get(this.payload, 'region'),
              zipCode: get(this.payload, 'zipCode'),
              city: get(this.payload, 'city'),
              street: get(this.payload, 'street'),
              building: get(this.payload, 'building'),
              house: get(this.payload, 'house'),
              flat: get(this.payload, 'flat'),
            },
            verificationLetter: toNumber(first(get(this.payload, 'verificationLetter', []))),
            companyCharter: toNumber(first(get(this.payload, 'companyCharter', []))),
            companyEgrul: toNumber(first(get(this.payload, 'companyEgrul', []))),
            companyElectronProtocol: toNumber(first(get(this.payload, 'companyElectronProtocol', []))),
            companyNalogCert: toNumber(first(get(this.payload, 'companyNalogCert', []))),
            companyOgrnCert: toNumber(first(get(this.payload, 'companyOgrnCert', []))),
            companyDirectorOrder: toNumber(first(get(this.payload, 'companyDirectorOrder', []))),
            contactPerson: {
              email: get(this.payload, 'contactPersonEmail'),
              fio: get(this.payload, 'contactPersonFio'),
              phone: get(this.payload, 'contactPersonPhone'),
            },
            organizationLeader: {
              email: get(this.payload, 'leaderEmail'),
              fio: get(this.payload, 'leaderFio'),
              phone: get(this.payload, 'leaderPhone'),
              position: get(this.payload, 'leaderPosition'),
            },
          }
        },
      });
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявку успешно сохранена',
        });
      }

      this.updateState();
    },
    async onUpdate() {
      const result = await this.updateAccount({
        id: get(this.detail, 'id'),
        fields: {
          verificationLetter: toNumber(first(get(this.payload, 'verificationLetter', []))),
          companyCharter: toNumber(first(get(this.payload, 'companyCharter', []))),
          companyEgrul: toNumber(first(get(this.payload, 'companyEgrul', []))),
          companyElectronProtocol: toNumber(first(get(this.payload, 'companyElectronProtocol', []))),
          companyNalogCert: toNumber(first(get(this.payload, 'companyNalogCert', []))),
          companyOgrnCert: toNumber(first(get(this.payload, 'companyOgrnCert', []))),
          companyDirectorOrder: toNumber(first(get(this.payload, 'companyDirectorOrder', []))),
          contactPerson: {
            email: get(this.payload, 'contactPersonEmail'),
            fio: get(this.payload, 'contactPersonFio'),
            phone: get(this.payload, 'contactPersonPhone'),
          },
          organizationLeader: {
            email: get(this.payload, 'leaderEmail'),
            fio: get(this.payload, 'leaderFio'),
            phone: get(this.payload, 'leaderPhone'),
            position: get(this.payload, 'leaderPosition'),
          },
        },
      });
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Аккаунт успешно сохранен',
        });
      }

      this.updateState();
    },
    async submitToApprove(comment) {
      const result = await this.applyActionAccountApplication({
        id: this.id,
        actionCode: this.isRework ?
          get(ACCOUNT_APPLICATION_ACTIONS, 'SEND_FROM_REWORK_BY_INITIATOR.value') :
          get(ACCOUNT_APPLICATION_ACTIONS, 'SEND_TO_APPROVE_BY_INITIATOR.value'),
        comment,
        noLogger: true,
      })
      if (isArray(result)) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно отправлена',
        });
      } else {
        try {
          const resultData = JSON.parse(get(result, 'message'))
          const status = get(resultData, 'status')
          this.accountExistDialog = status === 409

          if (!this.accountExistDialog) {
            const error = get(resultData, 'data')
            this.$notify({
              type: 'error',
              title: get(error, 'error'),
              text: get(error, 'error_description', get(error, 'result.message', get(error, 'message', ''))),
              duration: 3000,
            });
          }
        } catch (err) {
          console.error(err)
        }
      }

      this.getAccountApplicationDetail({ id: this.id })
      this.fetchTimeline({ id: this.id })
    },
    async onRevoke() {
      const result = await this.applyActionAccountApplication({
        id: this.id,
        actionCode: get(ACCOUNT_APPLICATION_ACTIONS, 'REVOKE_BY_INITIATOR.value'),
      })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно отозвана',
        });
      }

      this.getAccountApplicationDetail({ id: this.id })
    },
    async onReject () {
      const { isConfirmed } = await this.$swal("Вы уверены что хотите отклонить заявку?");
      if (!isConfirmed) return;
      const result = await this.applyActionAccountApplication({
        id: this.id,
        actionCode: get(ACCOUNT_APPLICATION_ACTIONS, 'REJECT_BY_REVIEWER.value'),
      })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно отклонена',
        });
      }

      this.getAccountApplicationDetail({ id: this.id })
    },
    async onReturnToApprove () {
      const { isConfirmed } = await this.$swal("Вы уверены что хотите разблокировать пользователя?");
      if (!isConfirmed) return;
      const result = await this.applyActionAccountApplication({
        id: this.id,
        actionCode: get(ACCOUNT_APPLICATION_ACTIONS, 'RETURN_TO_APPROVE_BY_REVIEWER.value'),
      })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Пользователь успешно разблокирован',
        });
      }

      this.getAccountApplicationDetail({ id: this.id })
    },
    async onRework(comment) {
      const result = await this.applyActionAccountApplication({
        id: this.id,
        actionCode: get(ACCOUNT_APPLICATION_ACTIONS, 'SEND_TO_REWORK_BY_REVIEWER.value'),
        comment,
      })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно отправлена',
        });
      }

      this.getAccountApplicationDetail({ id: this.id })
      this.fetchTimeline({ id: this.id })
    },
    async submitOnApprove(comment) {
      const result = await this.applyActionAccountApplication({
        id: this.id,
        actionCode: get(ACCOUNT_APPLICATION_ACTIONS, 'APPROVE_BY_REVIEWER.value'),
        comment,
      })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно согласована',
        });
      }

      this.getAccountApplicationDetail({ id: this.id })
      this.fetchTimeline({ id: this.id })
    },
    async onDelete() {
      const { isConfirmed } = await this.$swal("Вы уверены что хотите отклонить заявку? Действие нельзя будет отменить.");
      if (!isConfirmed) return;
      await this.deleteAccountApplication({ id: this.id })
      this.$router.push({ name: 'account/accountView', query: { tab: 'account' }  })
    },
    async onCreateAccount() {
      const { isConfirmed } = await this.$swal("Вы уверены что хотите создать Аккаунт Организации?");
      if (!isConfirmed) return;

      const result = await this.applyActionAccountApplication({
        id: this.id,
        actionCode: get(ACCOUNT_APPLICATION_ACTIONS, 'SAVE.value'),
      })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Аккаунт успешно создан',
        });
      }

      this.getAccountApplicationDetail({ id: this.id })
      this.fetchTimeline({ id: this.id })
    },
    async onInvite() {
      const result = await this.sendInviteToAccount({ id: this.id })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Заявка успешно отправлена',
        });
        this.$router.push({ name: 'account/accountView', query: { tab: 'account' } })
      }
    },
  },
  watch: {
    id: {
      immediate: true,
      handler: async function () {
        this.updateState();
      }
    },
  },
  beforeMount() {
    if (this.isDetail || this.isUser) {
      this.tabData = get(this.$route, 'query.tabAccountApplication');
      this.onTabChange(this.tabData);
    } else {
      this.tabData = 'account-application-basic';
    }
  }
}
</script>
<style module lang="scss">
.root {

}

.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 16px;
  margin-bottom: 16px;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}

.title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.87);
}

.status {
  padding: 4px 8px;
  text-transform: uppercase;
  line-height: 2;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  white-space: nowrap;

  &:empty { display: none; }
}

.fieldset {
  border: none;
  display: block;
}

.detailHeader {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
</style>
