<template>
  <div>
    <VForm v-model="valid" lazy-validation  ref="form" @submit.prevent="onSave" :disabled="pending || !canEdit">
      <div :class="$style.header">Регистрационные данные</div>
      <VRow>
        <VCol cols="12">
          <div v-for="(key, index) in ['FACT_ADRESS_JSON', 'POST_ADRESS_JSON']" :key="key">
            <div :class="$style.item" v-if="get(payload, [key, 'values'])">
              <div :class="$style.title">{{ get(payload, [key, 'label']) }}</div>
              <Address v-model="payload[key]['values'][0]" :previous="get(payload, [key, 'hint'])"
                       :match="[null, get(payload, ['FACT_ADRESS_JSON'])][index]"/>
            </div>
          </div>
        </VCol>
      </VRow>
      <SectionCard label="Данные о деятельности Организации">
        <div class="mb-5">
          <div :class="$style.header">Вид деятельности</div>
          <VRow>
            <VCol v-for="key in ['PROJ_SERVICES', 'WORK_WITH_TMC', 'PROJ_ILLQ_TEND']" cols="12" md="auto" :key="key">
              <VCheckbox v-if="get(payload, [key, 'values'])" v-model="payload[key]['values'][0]" class="mt-0 pt-0 mb-2"
                         :label="payload[key]['label']" hideDetails/>
            </VCol>
          </VRow>
        </div>
        <div class="mb-5">
          <div :class="$style.header">{{ get(payload, ['COMPANIES', 'label']) }}</div>
          <VRow>
            <VCol v-for="({ text, value }) in get(payload, ['COMPANIES', 'options'])" cols="3" :key="text">
              <VCheckbox
                class="mt-0 pt-0 mb-2"
                v-if="get(payload, ['COMPANIES', 'values'])"
                v-model="payload['COMPANIES']['values']"
                :value="value"
                hideDetails
              >
                <template v-slot:label>
                  <div v-html="text"/>
                </template>
              </VCheckbox>
            </VCol>
          </VRow>
        </div>
        <div v-if="get(payload, ['PROJ_SERVICES', 'values', 0])" class="mb-5">
          <ActivityInfoParticipation v-model="payload['SUPPLY_INFO']['values'][0]" :field="payload['SUPPLY_INFO']"/>
        </div>
        <div v-if="get(payload, ['PROJ_SERVICES', 'values', 0])" class="mb-5">
          <Activity :field="payload['ACTIVITIES']" :subscribe="payload['TYPE_WORK']"
                    :rules="[(v) => !!(v?.length && v?.length < 25) || 'Выберите от 1 до 24 видов выполняемых работ']"/>
        </div>
        <div v-if="get(payload, ['WORK_WITH_TMC', 'values', 0])" class="mb-5">
          <Activity :field="payload['ACTIVITY_TMC']" :subscribe="payload['TYPE_WORK_TMC']"
                    :rules="[(v) => !!v?.length || 'Выберите от 1 вида ТМЦ, оборудования']"/>
        </div>
      </SectionCard>
      <SectionCard
        label="Данные для портала аукционов по реализации лома и отходов производства предприятиями ЕВРАЗ (auction.evraz.com) и для интернет-магазина ТМЦ (tmc.evraz.com)">
        <div class="mb-5">
          <VAlert type="warning" dense outlined>Допустимые форматы файлов: .doc, .docx, .pdf, .jpg, .png. Максимальный
            размер файла - 20 MB.
          </VAlert>
          <VRow>
            <VCol
              v-for="key in ['ARTICLES_OF_ASSOC', 'EGRUL', 'ELECTION_PROTOCOL', 'NALOG_CERT', 'OGRN_LIC', 'DIRECTOR_ORDER', 'LICEN_METALL', 'LICEN_WASTE_1_4', 'LIC_WASTE_1_4_AKB', 'SPEC_RECORD', 'APPLICATION_FORM']"
              cols="12" md="6" :key="key">
              <VRow dense>
                <VCol v-if="get(payload, [key, 'label']) && get(payload, [key])" cols="12">
                  <div :class="$style.label" v-html="payload[key]['label']"/>
                </VCol>
                <VCol v-if="get(payload, [key, 'values'])" cols="12">
                  <FileFieldMultiple
                    showCreateDate
                    :disabled="!canEdit"
                    :value="payload[key]['values'].map(value => ({ value }))"
                    :filled="!!get(payload, [key, 'values'], []).length"
                    @input="(values) => payload[key]['values'] = values.map(({ value }) => value)"
                    :entity="id"
                  />
                </VCol>
              </VRow>
            </VCol>
          </VRow>
        </div>
      </SectionCard>
    </VForm>
    <VFooter :class="$style.vfooter" app v-if="canEdit">
      <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" :class="$style.progress" />
      <div class="py-3 grow">
        <VBtn color="primary" class="mr-2" depressed :disabled="pending || !diff || !valid" @click="onSave">Сохранить</VBtn>
        <VBtn color="secondary" class="mr-2" depressed :disabled="pending || !diff" @click="onReset">Сбросить</VBtn>
      </div>
    </VFooter>
  </div>
</template>

<script>

import Address from '@/components/user/Profile/components/Address.vue';
import {get} from 'lodash-es';
import {mapActions, mapGetters} from 'vuex';
import SectionCard from '@/components/spa/SectionCard/SectionCard.vue';
import Activity from '@/components/user/Profile/components/Activity.vue';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple.vue';
import {ACCOUNT_APPLICATION_TYPES} from '@/store/account-application/enums';
import ActivityInfoParticipation from '@/components/user/Profile/components/ActivityInfoParticipation.vue';

export default {
  name: 'AccountApplicationExtra',
  components: { ActivityInfoParticipation, FileFieldMultiple, Activity, SectionCard, Address },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      valid: true,
      payload: this.getPayload(),
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      accountExtra: 'account/extra',
      pending: 'account/pending',
    }),
    canEdit() {
      return this.isInitiator;
    },
    isInitiator() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'INITIATOR.value'), 'account');
    },
    diff() {
      return JSON.stringify(this.getPayload()) !== JSON.stringify(this.payload);
    },
  },
  methods: {
    get,
    ...mapActions({
      getAccountExtra: 'account/getAccountExtra',
      updateAccountExtra: 'account/updateAccountExtra',
    }),
    getPayload() {
      return JSON.parse(JSON.stringify(get(this.accountExtra, 'values', {})));
    },
    async onSave() {
      await this.$refs.form.validate();
      if (!this.valid) {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          text: 'Ошибка валидации',
        });
        return
      }
      const result = await this.updateAccountExtra({ item: this.payload })
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Профиль успешно изменен',
        });
      }
      this.$set(this, 'payload', this.getPayload());
    },
    onReset() {
      this.$set(this, 'payload', this.getPayload());
    },
    async updateState() {
      await this.getAccountExtra({ accountId: this.id });
      this.$set(this, 'payload', this.getPayload());
    }
  },
  beforeMount() {
    this.updateState();
  }
}
</script>

<style module lang="scss">
.header {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.87);

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.root {
  :global(.v-expansion-panel) {
    border: 1px solid rgb(238, 238, 238);
  }
}

.vfooter {
  z-index: 10; // TODO: поправить наложение футтера
}

.progress {
  top: 0;
}

.label {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}

.title {
  font-weight: bold;
  font-size: 16px;
  color: #212121;
  margin-bottom: 15px;
}

.subscribe {
  cursor: pointer;
  margin-right: 10px;
}

.item {
  padding: 20px;
  margin: 0 -16px;

  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
}

.link {
  color: #f57f29;
  text-decoration: underline;
}

.remove {
  color: #ff5252;
  cursor: pointer;
  text-decoration: none;
  float: right;
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.footer {
  padding: 20px 16px 0;
  margin: 0 -16px;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
}

.roleCheckbox {
  pointer-events: none;
}
</style>
