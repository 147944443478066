<template>
  <VAutocomplete
      :label="label"
      :value="value"
      :search-input.sync="search"
      :items="options"
      :disabled="disabled"
      :readonly="readonly"
      :rules="rules"
      :loading="loading"
      @change="onChange"
      @blur="onBlur"
      outlined
      dense
      autocomplete="chrome-off"
      hideDetails="auto"
  />
</template>

<script>
import { get, map, throttle, isEmpty } from 'lodash-es';
import { address } from '@/api/dadata';

export default {
  name: 'InputStreetDadata',
  props: {
    label: { type: String },
    value: { type: String },
    disabled: { type: Boolean },
    readonly: { type: Boolean },
    rules: { type: Array, default: () => []},
    locations: { type: Array, default: () => []},
    saveUserValue: { type: Boolean, default: true },
  },
  data() {
    return {
      loading: false,
      search: '',
      suggestions: [],
    }
  },
  computed: {
    options() {
      if (isEmpty(this.suggestions)) {
        if (this.value) return [this.value];
        else return [];
      }
      return map(this.suggestions, (suggestion) => {
        return this.formatter(suggestion);
      });
    }
  },
  methods: {
    onChange: function(value) {
      this.$emit('input', value);
    },
    formatter(item) {
      return get(item, 'value');
    },
    onSearch: throttle(function(query) {
      if (query) {
        this.loading = true;
        address(query, {
          restrict_value: true,
          from_bound: {  value: 'street' },
          to_bound: { value: 'street' },
          locations: this.locations,
        }).then(({ suggestions }) => {
          this.loading = false;
          this.$set(this, 'suggestions', suggestions);
        })
        .catch(() => this.loading = false);
      }
    }, 1000),
    onBlur: function() {
      if (!this.search || (this.saveUserValue && isEmpty(this.options))) {
        this.$emit('input', this.search);
      }
    }
  },
  watch: {
    value: {
      handler: function() {
        this.suggestions = [];
      },
    },
    search: {
      handler: function(query) {
        if (query !== this.value) this.onSearch(query);
      },
    },
  }
}
</script>
