<template>
  <div>
    <div :class="$style.header">Единая база инспекции</div>
    <VRow>
      <VCol cols="12">
        <VCheckbox
          v-model="payload['hasEdbiAccess']"
          @change="updateSettings"
          :disabled="!isAdministrator || pending"
          label="Доступ к Единой Базе Инспекций"
          hideDetails outlined dense class="d-inline-flex"
        />
      </VCol>
      <VCol cols="12">
        <VCheckbox
          v-model="payload['isActiveAccount']"
          @change="updateSettings"
          :disabled="!isAdministrator || pending"
          label="Аккаунт активен"
          hideDetails outlined dense class="d-inline-flex"
        />
      </VCol>
    </VRow>
    <SectionCard label="Выбор владельца аккаунта">
      <StaticField v-if="get(this.detail, 'author.fio')" class="mb-4" label="Владелец" :value="get(this.detail, 'author.fio')"/>
      <template v-if="isAdministrator">
        <VBtn color="primary" class="mr-2" depressed @click="onBindAccount">{{ get(this.detail, 'author.fio') ? 'Сменить владельца' : 'Добавить владельца' }}</VBtn>
        <VBtn v-if="get(this.detail, 'author.fio')" color="error" class="mr-2" depressed @click="onUnBindAccount">Удалить владельца</VBtn>
      </template>
    </SectionCard>
    <template v-if="isAdministrator">
      <VBtn v-if="isActive" color="error" class="mr-2" depressed @click="onDeleteUser">Заблокировать аккаунт</VBtn>
      <VBtn v-else color="primary" class="mr-2" depressed @click="onRecoverUser">Разблокировать аккаунт</VBtn>
    </template>
  </div>
</template>

<script>

import { get, toNumber } from "lodash-es";
import { mapActions, mapGetters } from "vuex";
import SectionCard from "@/components/spa/SectionCard/SectionCard.vue";
import { ACCOUNT_APPLICATION_TYPES } from "@/store/account-application/enums";
import StaticField from "@/components/general/StaticField/StaticField.vue";

export default {
  name: 'AccountApplicationSettings',
  components: { StaticField, SectionCard },
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      valid: true,
      payload: this.getPayload(),
    }
  },
  computed: {
    ...mapGetters({
      hasRole: 'user/hasRole',
      accountSettings: 'account/settings',
      pending: 'account/pending',
      accountDetail: 'account/detail',
    }),
    detail() {
      return this.accountDetail;
    },
    isAdministrator() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'ADMINISTRATOR.value'), 'account');
    },
    isAccountObserver() {
      return this.hasRole(get(ACCOUNT_APPLICATION_TYPES, 'ACCOUNT_OBSERVER.value'), 'account');
    },
    isActive(){
      return this.detail.isActive;
    },
  },
  methods: {
    get,
    ...mapActions({
      getAccountSettings: 'account/getAccountSettings',
      updateAccountSettings: 'account/updateAccountSettings',
      deleteUser: 'account/deleteAccount',
      recoverUser: 'account/recoverAccount',
      changeAuthor: 'account/changeAuthor',
      getAccountDetail: 'account/getAccountDetail',
    }),
    getPayload() {
      return JSON.parse(JSON.stringify(this.accountSettings || {}));
    },
    async updateSettings() {
      const result = await this.updateAccountSettings({
        id: toNumber(get(this.accountSettings, 'id')),
        fields: {
          hasEdbiAccess: get(this.payload, 'hasEdbiAccess'),
          isActiveAccount: get(this.payload, 'isActiveAccount'),
        },
      });
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Данные успешно сохранены',
        });
      }
      this.getAccountSettings({ accountId: this.id })
    },
    onBindAccount() {
      this.$router.push({ name: 'account/applicationBindAccount', params: { user: this.id } })
    },
    async onUnBindAccount() {
      await this.changeAuthor({ id: toNumber(this.id), authorId: null })
      this.getAccountDetail({ id: this.id })
    },
    async onDeleteUser() {
      const { isConfirmed } = await this.$swal("Вы уверены что хотите заблокировать аккаунт?");
      if (!isConfirmed) return;
      const result = await this.deleteUser({
        id: toNumber(this.id),
      });
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Аккаунт успешно удалён',
        });
      }
      this.getAccountDetail({ id: this.id })
    },
    async onRecoverUser() {
      const { isConfirmed } = await this.$swal("Вы уверены что хотите восстановить аккаунт?");
      if (!isConfirmed) return;
      if (!isConfirmed) return;
      const result = await this.recoverUser({
        id: this.id,
      });
      if (result) {
        this.$notify({
          type: 'success',
          title: 'Успех',
          text: 'Аккаунт успешно восстановлен',
        });
      }
      this.getAccountDetail({ id: this.id })
    },
    async updateState() {
      await this.getAccountSettings({ accountId: this.id });
      this.$set(this, 'payload', this.getPayload());
    }
  },
  beforeMount() {
    this.updateState();
  }
}
</script>

<style module lang="scss">
.header {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  color: rgba(0, 0, 0, 0.87);

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
</style>
